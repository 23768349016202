import React, { useState } from 'react';

const TransportMarcelApp = () => {
  const [etape, setEtape] = useState(1);
  const [donnees, setDonnees] = useState({
    typeClient: 'professionnel',
    nom: '',
    email: '',
    telephone: '',
    siret: '',
    tva: '',
    adresseFacturation: '',
    adresseEnlevement: '',
    adresseDestination: '',
    distance: '',
    typeService: 'Standard',
    typeVehicule: 'Voiture',
    typeColis: 'Standard',
    date: '',
    heure: ''
  });

  const gererChangementInput = (e) => {
    const { name, value } = e.target;
    setDonnees(etatPrecedent => ({
      ...etatPrecedent,
      [name]: value
    }));
  };

  const etapeSuivante = () => {
    const nouvelleEtape = etape + 1;
    setEtape(nouvelleEtape);
  };

  const etapePrecedente = () => setEtape(etapePrecedente => etapePrecedente - 1);

  const calculerPrixEstime = () => {
    const distance = parseFloat(donnees.distance) || 0;
    let prixBase = 0;

    if (distance <= 10) {
      prixBase = 15 + distance * 1.7;
    } else if (distance <= 20) {
      prixBase = 32 + (distance - 10) * 1.6;
    } else if (distance <= 30) {
      prixBase = 48 + (distance - 20) * 1.5;
    } else if (distance <= 50) {
      prixBase = 63 + (distance - 30) * 1.4;
    } else {
      prixBase = 91 + (distance - 50) * 1.3;
    }

    const facteursVehicule = {
      'Voiture': 1,
      'Fourgonnette': 1.2,
      'Camion léger': 1.5,
      'Vélo': 0.8,
      'Moto': 0.9
    };

    const facteursColis = {
      'Standard': 1,
      'Fragile': 1.1,
      'Volumineux': 0, // Prix sur demande
      'Frigorifique': 0, // Prix sur demande
      'Déchets DASRI': 0, // Prix sur demande
      'Médical': 0 // Prix sur demande
    };

    // Si c'est un type de colis particulier qui nécessite une consultation
    if (['Volumineux', 'Frigorifique', 'Déchets DASRI', 'Médical'].includes(donnees.typeColis)) {
      return "Sur demande";
    }

    prixBase *= facteursVehicule[donnees.typeVehicule] || 1;
    prixBase *= facteursColis[donnees.typeColis] || 1;

    // Appliquer le facteur selon le type de service
    if (donnees.typeService === 'Express') {
      prixBase *= 1.5; // +50% pour Express
    } else if (donnees.typeService === 'Urgent') {
      prixBase *= 2; // +100% pour Urgent
    }
    
    // Augmenter le prix de 20% pour les particuliers
    if (donnees.typeClient === 'particulier') {
      prixBase *= 1.2; // +20% pour les particuliers
    }

    return (prixBase * 1.2).toFixed(2);  // TTC
  };

  // Style pour les boutons en fonction du style DevisForm
  const styleBouton = "text-white px-5 py-3 rounded inline-block text-lg font-medium";
  const styleBoutonPrimaire = { backgroundColor: '#C4392D' };
  const styleBoutonSecondaire = { backgroundColor: '#6AA84F' };
  const styleBoutonWhatsApp = { backgroundColor: '#25D366' };

  const afficherInfoClient = () => (
    <div className="mt-4 space-y-3 flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-4">Informations Client</h2>
      <form onSubmit={(e) => { e.preventDefault(); etapeSuivante(); }} className="w-full max-w-lg">
        <div className="flex flex-col items-center">
          <label className="block text-center text-lg font-medium mb-1">Type de client</label>
          <select
            className="border p-2 w-64 text-lg text-center"
            name="typeClient"
            value={donnees.typeClient}
            onChange={gererChangementInput}
          >
            <option value="professionnel">Professionnel</option>
            <option value="particulier">Particulier</option>
          </select>
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Nom complet</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="text"
            name="nom"
            placeholder="Votre nom"
            value={donnees.nom}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Email</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="email"
            name="email"
            placeholder="votre@email.com"
            value={donnees.email}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Téléphone</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="tel"
            name="telephone"
            placeholder="Votre numéro"
            value={donnees.telephone}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        {donnees.typeClient !== 'particulier' && (
          <>
            <div className="flex flex-col items-center mt-3">
              <label className="block text-center text-lg font-medium mb-1">N° SIRET</label>
              <input
                className="border p-2 w-64 text-lg text-center"
                type="text"
                name="siret"
                placeholder="N° SIRET"
                value={donnees.siret}
                onChange={gererChangementInput}
                required
              />
            </div>
            
            <div className="flex flex-col items-center mt-3">
              <label className="block text-center text-lg font-medium mb-1">N° TVA (optionnel)</label>
              <input
                className="border p-2 w-64 text-lg text-center"
                type="text"
                name="tva"
                placeholder="N° TVA (optionnel)"
                value={donnees.tva}
                onChange={gererChangementInput}
              />
            </div>
            
            <div className="flex flex-col items-center mt-3">
              <label className="block text-center text-lg font-medium mb-1">Adresse de facturation</label>
              <input
                className="border p-2 w-64 text-lg text-center"
                type="text"
                name="adresseFacturation"
                placeholder="Adresse de facturation"
                value={donnees.adresseFacturation}
                onChange={gererChangementInput}
                required
              />
            </div>
          </>
        )}
        
        <div className="flex flex-wrap gap-2 mt-5 justify-center">
          <button 
            type="submit" 
            className={styleBouton}
            style={styleBoutonPrimaire}>
            Suivant
          </button>
        </div>
      </form>
    </div>
  );

  const afficherInfoAdresse = () => (
    <div className="mt-4 space-y-3 flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-4">Adresses</h2>
      <form onSubmit={(e) => { e.preventDefault(); etapeSuivante(); }} className="w-full max-w-lg">
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Adresse d'enlèvement</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="text"
            name="adresseEnlevement"
            placeholder="Adresse d'enlèvement"
            value={donnees.adresseEnlevement}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Adresse de destination</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="text"
            name="adresseDestination"
            placeholder="Adresse de destination"
            value={donnees.adresseDestination}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Distance (km)</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="text"
            name="distance"
            placeholder="Distance (km)"
            value={donnees.distance}
            onChange={gererChangementInput}
            required
          />
          <p className="text-sm text-red-600 mt-1">*Obligatoire pour un tarif précis. Toute information erronée entraînera un recalcul du tarif.</p>
        </div>
        
        <div className="flex flex-wrap gap-2 mt-5 justify-center">
          <button 
            type="button" 
            className={styleBouton}
            style={styleBoutonSecondaire}
            onClick={etapePrecedente}>
            Précédent
          </button>
          <button 
            type="submit" 
            className={styleBouton}
            style={styleBoutonPrimaire}>
            Suivant
          </button>
        </div>
      </form>
    </div>
  );

  const afficherInfoService = () => (
    <div className="mt-4 space-y-3 flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-4">Détails du Service</h2>
      <form onSubmit={(e) => { e.preventDefault(); etapeSuivante(); }} className="w-full max-w-lg">
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Type de service</label>
          <select
            className="border p-2 w-64 text-lg text-center"
            name="typeService"
            value={donnees.typeService}
            onChange={gererChangementInput}
          >
            <option value="Standard">Standard (délai 24-48 heures)</option>
            <option value="Express">Express (moins de 24 heures)</option>
            <option value="Urgent">Urgent (immédiat à 4h maximum, rayon de 200km)</option>
          </select>
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Type de véhicule</label>
          <select
            className="border p-2 w-64 text-lg text-center"
            name="typeVehicule"
            value={donnees.typeVehicule}
            onChange={gererChangementInput}
          >
            <option value="Voiture">Voiture (Style Clio Société)</option>
            <option value="Fourgonnette">Fourgonnette (Style Renault Kangoo)</option>
            <option value="Camion léger">Camion léger (toute taille disponible en -3,5t)</option>
            <option value="Vélo">Vélo (disponible uniquement sur Nice -5km)</option>
            <option value="Moto">Moto (Style scooter)</option>
          </select>
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Type de colis</label>
          <select
            className="border p-2 w-64 text-lg text-center"
            name="typeColis"
            value={donnees.typeColis}
            onChange={gererChangementInput}
          >
            <option value="Standard">Standard</option>
            <option value="Fragile">Fragile</option>
            <option value="Volumineux">Volumineux (nous consulter)</option>
            <option value="Frigorifique">Frigorifique (nous consulter)</option>
            <option value="Déchets DASRI">Déchets DASRI (nous consulter)</option>
            <option value="Médical">Médical (nous consulter)</option>
          </select>
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Date</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="date"
            name="date"
            value={donnees.date}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <div className="flex flex-col items-center mt-3">
          <label className="block text-center text-lg font-medium mb-1">Heure</label>
          <input
            className="border p-2 w-64 text-lg text-center"
            type="time"
            name="heure"
            value={donnees.heure}
            onChange={gererChangementInput}
            required
          />
        </div>
        
        <p className="text-sm mt-5 text-center">* Sous réserve de place et de disponibilité</p>
        
        <div className="flex flex-wrap gap-2 mt-5 justify-center">
          <button 
            type="button" 
            className={styleBouton}
            style={styleBoutonSecondaire}
            onClick={etapePrecedente}>
            Précédent
          </button>
          <button 
            type="submit" 
            className={styleBouton}
            style={styleBoutonPrimaire}>
            Suivant
          </button>
        </div>
      </form>
    </div>
  );

  const afficherConfirmation = () => (
    <div className="mt-4 space-y-3 text-center flex flex-col items-center">
      <h2 className="text-2xl font-bold mb-4">Confirmation</h2>
      <div className="mt-5 border p-6 rounded-lg space-y-3 mx-auto max-w-xl text-lg">
        <p><strong>Type de client :</strong> {donnees.typeClient}</p>
        <p><strong>Nom :</strong> {donnees.nom}</p>
        <p><strong>Email :</strong> {donnees.email}</p>
        <p><strong>Téléphone :</strong> {donnees.telephone}</p>
        {donnees.typeClient !== 'particulier' && (
          <>
            <p><strong>N° SIRET :</strong> {donnees.siret}</p>
            <p><strong>N° TVA :</strong> {donnees.tva || 'Non renseigné'}</p>
            <p><strong>Adresse de facturation :</strong> {donnees.adresseFacturation}</p>
          </>
        )}
        <p><strong>Adresse d'enlèvement :</strong> {donnees.adresseEnlevement}</p>
        <p><strong>Adresse de destination :</strong> {donnees.adresseDestination}</p>
        <p><strong>Distance :</strong> {donnees.distance} km</p>
        <p><strong>Type de service :</strong> {donnees.typeService}</p>
        <p><strong>Type de véhicule :</strong> {donnees.typeVehicule}</p>
        <p><strong>Type de colis :</strong> {donnees.typeColis}</p>
        <p><strong>Date :</strong> {donnees.date}</p>
        <p><strong>Heure :</strong> {donnees.heure}</p>
      </div>
      <div className="mt-6 text-2xl font-bold">
        Prix estimé : {calculerPrixEstime() === "Sur demande" ? 
          <span className="text-red-600">Sur demande</span> : 
          `${calculerPrixEstime()} € TTC`}
      </div>
      <div className="flex flex-wrap gap-2 mt-5 justify-center">
        <button 
          onClick={etapePrecedente}
          className={styleBouton}
          style={styleBoutonSecondaire}>
          Précédent
        </button>
        <button 
          onClick={etapeSuivante}
          className={styleBouton}
          style={styleBoutonPrimaire}>
          Confirmer la réservation
        </button>
      </div>
    </div>
  );

  const afficherConfirmationFinale = () => {
    const prix = calculerPrixEstime();
    
    // Préparer le message de réservation
    const messageReservation = `Nouvelle réservation:

Nom: ${donnees.nom}
Email: ${donnees.email}
Téléphone: ${donnees.telephone}
${donnees.typeClient !== 'particulier' ? `SIRET: ${donnees.siret}
TVA: ${donnees.tva || 'Non renseigné'}
Adresse de facturation: ${donnees.adresseFacturation}` : ''}
Adresse d'enlèvement: ${donnees.adresseEnlevement}
Adresse de destination: ${donnees.adresseDestination}
Distance: ${donnees.distance} km
Type de service: ${donnees.typeService}
Type de véhicule: ${donnees.typeVehicule}
Type de colis: ${donnees.typeColis}
Date: ${donnees.date}
Heure: ${donnees.heure}
Prix estimé: ${prix} € TTC
`;

    // Fonctions pour ouvrir les différentes méthodes de contact tout en gardant les données
    const envoyerParWhatsApp = () => {
      window.open(`https://wa.me/33649292905?text=${encodeURIComponent(messageReservation)}`, '_blank');
    };
    
    const envoyerParEmail = () => {
      window.open(`mailto:sarl.sftma@gmail.com?subject=${encodeURIComponent('Nouvelle réservation')}&body=${encodeURIComponent(messageReservation)}`, '_blank');
    };
    
    const envoyerParSMS = () => {
      window.open(`sms:+33649292905?body=${encodeURIComponent(messageReservation)}`, '_blank');
    };

    return (
      <div className="mt-4 space-y-3 text-center flex flex-col items-center">
        <h2 className="text-2xl font-bold mb-4">Réservation enregistrée</h2>
        
        <div className="mt-6 border p-5 rounded-lg mx-auto max-w-2xl text-lg text-left">
          <h3 className="text-xl font-bold mb-3">1. Envoyez votre commande</h3>
          
          <p className="mb-3">
            Pour finaliser votre réservation, il est obligatoire de nous transmettre votre commande afin qu'elle soit validée par notre équipe.
          </p>
          
          <p className="mb-3">
            Merci de nous l'envoyer via l'un des moyens suivants :<br />
            <strong>WhatsApp, e-mail ou SMS</strong>
          </p>
          
          <div className="flex flex-wrap gap-2 justify-center mb-6">
            <button 
              onClick={envoyerParWhatsApp}
              className={styleBouton}
              style={styleBoutonWhatsApp}
            >
              Envoyer via WhatsApp
            </button>
            <button 
              onClick={envoyerParEmail}
              className={styleBouton}
              style={styleBoutonPrimaire}
            >
              Envoyer par Email
            </button>
            <button 
              onClick={envoyerParSMS}
              className={styleBouton}
              style={styleBoutonSecondaire}
            >
              Envoyer par SMS
            </button>
          </div>
          
          <p className="mb-3">
            Pour assurer un service réactif et sur mesure, merci de nous transmettre votre commande dès que votre réservation est effectuée.<br />
            Notre équipe vous recontactera rapidement par message ou téléphone pour :
          </p>
          
          <ul className="list-disc pl-5 mb-3">
            <li>confirmer la prise en charge,</li>
            <li>valider nos disponibilités,</li>
            <li>et vérifier que l'estimation tarifaire affichée correspond bien à votre demande.</li>
          </ul>
          
          <hr className="my-5 border-gray-300" />
          
          <h3 className="text-xl font-bold mb-3">2. Informations sur le tarif et le paiement</h3>
          
          <p className="text-xl font-bold mb-3">
            Montant estimé : {prix} € TTC 
            {donnees.typeClient !== 'particulier' && (
              <span className="text-base font-normal"> (TVA récupérable pour les professionnels)</span>
            )}
          </p>
          
          <p className="mb-3">
            Après validation de votre commande par notre équipe, un lien de paiement sécurisé ou les instructions de règlement vous seront transmis. Le paiement pourra se faire par carte bancaire ou par virement.
          </p>
          
          <p className="mb-3 font-semibold">
            Important : Aucun paiement ne vous sera demandé tant que notre équipe n'aura pas confirmé les détails de votre transport et validé le tarif.
          </p>
          
          <ul className="list-disc pl-5 mb-3">
            <li><strong>Nouveaux clients :</strong> le paiement est obligatoire <strong>au plus tard lors de la livraison</strong>.</li>
            <li><strong>Clients professionnels :</strong> un compte client avec paiement différé peut être mis en place uniquement pour les clients réguliers. Merci de contacter notre équipe pour en faire la demande.</li>
          </ul>
          
          <hr className="my-5 border-gray-300" />
          
          <h3 className="text-xl font-bold mb-3">3. Finalisation de la réservation</h3>
          
          <p className="mb-3">
            Votre réservation sera prise en charge une fois votre commande reçue et validée.
          </p>
          
          <p className="mb-3">
            <strong>Remarque :</strong> Toutes nos prestations doivent être réglées <strong>au plus tard lors de la livraison</strong> de la marchandise.
          </p>
          
          <p className="mt-5 mb-3 text-center font-semibold">
            Merci de votre confiance et au plaisir de collaborer avec vous.<br />
            L'équipe Transports Marcel
          </p>
        </div>
        
        <div className="mt-8 pt-4 border-t border-gray-200 text-center text-lg">
          <h3 className="font-semibold text-xl"><strong>Nos informations</strong></h3>
          <p className="text-lg font-bold" style={{ color: '#6AA84F' }}>Ouvert 24/24 - 7j/7</p>
          <p>Téléphone : <a href="tel:+33649292905" style={{ color: '#C4392D' }}>06 49 29 29 05</a></p>
          <p>Email : <a href="mailto:contact@transportsmarcel.fr" style={{ color: '#C4392D' }}>contact@transportsmarcel.fr</a></p>
          <p>Site web : <a href="https://transportsmarcel.fr" target="_blank" rel="noopener noreferrer" style={{ color: '#C4392D' }}>transportsmarcel.fr</a></p>
          <p className="mt-2">Pour plus d'informations sur notre société, veuillez consulter : <a href="https://sftma.fr" target="_blank" rel="noopener noreferrer" style={{ color: '#C4392D' }}>sftma.fr</a></p>
        </div>
      </div>
    );
  };

  const afficherEtape = () => {
    switch (etape) {
      case 1: return afficherInfoClient();
      case 2: return afficherInfoAdresse();
      case 3: return afficherInfoService();
      case 4: return afficherConfirmation();
      case 5: return afficherConfirmationFinale();
      default: return <div>Étape non trouvée</div>;
    }
  };

  return (
    <div className="w-full max-w-5xl mx-auto p-4">
      <div className="w-full text-center mb-8">
        <h1 className="text-6xl font-bold" style={{ color: '#C4392D' }}>
          TransportsMarcel
        </h1>
        <div className="mt-3 text-center">
          <a href="https://transportsmarcel.fr" className="text-blue-600 hover:underline text-xl">
            www.transportsmarcel.fr
          </a>
        </div>
        {etape === 1 && (
          <h2 className="text-3xl font-semibold mt-4" style={{ color: '#6AA84F' }}>
            Réservez votre livraison en ligne avec estimation immédiate du prix
          </h2>
        )}
      </div>
      
      {etape < 5 && (
        <div className="flex justify-center mb-6">
          <div className="flex space-x-4">
            {[1, 2, 3, 4].map(num => (
              <div 
                key={num}
                className={`w-5 h-5 rounded-full ${etape >= num ? 'bg-red-600' : 'bg-gray-300'}`}
              />
            ))}
          </div>
        </div>
      )}
      
      {afficherEtape()}
    </div>
  );
};

export default TransportMarcelApp;
